function normalize(phoneNumber: string): string {
  let trimmedNumber = phoneNumber.replace(/[^\d+]/g, "");
  if (trimmedNumber.includes("+")) {
    return trimmedNumber.replace(/^\+46/, "0");
  }

  if (trimmedNumber.startsWith("00")) {
    trimmedNumber = trimmedNumber.replace(/^00/, "");
  }

  return trimmedNumber.replace(/^46/, "0");
}

export function formatPhoneNumber(phoneNumber: string | undefined | null): string {
  if (!phoneNumber) {
    return "";
  }

  const normalized = normalize(phoneNumber);
  switch (normalized.length) {
    case 10:
      return normalized.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1-$2 $3 $4");
    case 14:
      return normalized.replace(/^(\d{3})(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1-$2 $3 $4 $5");
    default:
      return phoneNumber;
  }
}

export function formatToMsisdn(phoneNumber: string | undefined | null): string {
  return phoneNumber
    ? phoneNumber.replace(/^07/, "467").replace(/^\+/, "").replace(/^00/, "").replace(/[\s-]/g, "")
    : "";
}
